// extracted by mini-css-extract-plugin
export var authors = "article-header-module--authors--D8dva";
export var avatar = "article-header-module--avatar--KJ4Zl";
export var avatarImg = "article-header-module--avatarImg--K7Fgo";
export var avatars = "article-header-module--avatars--ttZJg";
export var dateBlock = "article-header-module--dateBlock--OSk0i";
export var hero = "article-header-module--hero--NuBpQ";
export var heroImageCopyright = "article-header-module--heroImageCopyright--WjHbh";
export var mediaBody = "article-header-module--mediaBody--F-0N4";
export var pageHeader = "article-header-module--pageHeader--0yrTg";
export var pageSubHeader = "article-header-module--pageSubHeader--U1k-F";
export var svg1 = "article-header-module--svg1--AszqG";
export var svg2 = "article-header-module--svg2--hfaE-";
export var title = "article-header-module--title--vQ+fN";