import React from 'react'
import { Link } from "gatsby";

import * as styles from './offer-affiliatenotice.module.css'

export default function OfferAffiliateNotice(props) {
    return (
        <>
            <div className={styles.affiliateNotice}>
                All product recommendations are thoroughly and carefully vetted based 
                on our experience. If you choose to buy then we may earn a commission 
                at no additional cost to you. More <Link 
                to="/disclosure/" rel="nofollow">disclosure details</Link>.</div>
        </>
    )
  }