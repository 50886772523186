import React from "react";

import { graphql } from "gatsby";

import Layout from '../containers/layout'

import BlogPost from '../components/blog-post'
import MetaSchemaSEO from '../components/seo';

// load infinitescroll now to to speed up lazy load
import InfiniteScroll from "react-infinite-scroll-component"; // keep this!

//import LoadMore from '../components/load-more'
const ClientSideOnlyLazy = React.lazy(() =>
  import('../components/load-more')
)



export const query = graphql`
  query ArticleQuery($pageId: ID!) {
    strapi {
      page( id: $pageId ) {
        id
        title
        title_short
        excerpt
        summary: excerpt
        published_at
        created_at
        updated_at
        page_type{
          name
          show_hero_image
        }
        slug
        site_section: page_type{
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 1200,
                aspectRatio: 1.8,
                transformOptions: {cropFocus:CENTER})
            }
          }
        }
        authors {
          id
          name
          slug
          sameAs
          entity_type {
            slug_prefix
            schema_thing_type
            name
          }
          main_image {
            url
            caption
            alternativeText
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 80,
                  width: 50,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
        body_introduction
        body {
          __typename
          ... on STRAPI_ComponentDataTabularData {
            id
            title
            body
            data
            data_updated_at
            description
            footnote
            jsonld_schema_data
            show_graph
            show_table
          }
          ... on STRAPI_ComponentContentBikeMap {
            id
          }
          ... on STRAPI_ComponentContentListTopN {
            id
            introduction
            section_image {
              url
              caption
              alternativeText
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 755
                  )
                }
              }
            }
            description
            title
            offers {
              id
              title
              offer
              external_url
              img_url
              img_alt
              page{
                slug
              }
            }
            is_ordered
            page {
              slug
              title
              page_type {
                name
                slug_prefix
              }
              parent {
                slug
                page_type {
                  name
                  slug_prefix
                }
              }
            }
          }
          ... on STRAPI_ComponentContentEmbeddedHtml {
            id
            raw_html
          }
          ... on STRAPI_ComponentMediaImage {
            id
          }
          ... on STRAPI_ComponentContentQuestionAndAnswer {
            id
            Question
            answer
          }
          ... on STRAPI_ComponentContentCalloutPage {
            page {
              slug
              primary_topic
              page_type {
                name
                slug_prefix
              }
              site_section: page_type{
                name
                slug_prefix
              }
              title
              title_short
              excerpt
              page_value
              updated_at
              parent {
                title
                title_short
                slug
                page_type {
                  name
                  slug_prefix
                }
              }
              main_image {
                url
                caption
                alternativeText
                imageFile{
                  childImageSharp{
                    gatsbyImageData(
                      quality: 70,
                      width: 350,
                      aspectRatio: 1,
                      transformOptions: {cropFocus:ENTROPY})
                  }
                }
              }
            }
          }
        }
        body_conclusion
        authorities {
          id
          name
          slug
          entity_type {
            slug_prefix
            name
          }
        }
        primary_topic
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
          include_parent_when_in_breadcrumb_as_parent
          parent {
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
        }
        page_type {
          name
          slug_prefix
          show_hero_image
        }
        children {
          id
          canonical_url
          slug
          primary_topic
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          title_short
          excerpt
          page_value
          updated_at
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image {
            url
            caption
            alternativeText
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 70,
                  width: 350,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
        next_pages {
          id
          canonical_url
          slug
          page_type {
            name
            slug_prefix
          }
        }
        keyphrases {
          id
          keyphrase
        }
        related_pages {
          id
          title
          slug
          page_type {
            name
            slug_prefix
          }
        }
        categorytopic_pages {
          id
          title
          title_short
          primary_topic
          slug
          page_type {
            name
            slug_prefix
          }
        }
        recommended_pages {
          id
          title
          slug
          primary_topic
          page_type {
            name
            slug_prefix
          }
          main_image {
            url
            caption
            alternativeText
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 200,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
      }
    }
  }
`;




const Page = ( {location, data} ) => {
  const article = data.strapi.page;

  const isSSR = typeof window === "undefined"
  
  //<LoadMore nextArticles={article.next_pages} location={location}/>
  
  const nextPages = article?.next_pages || []
  const currentPage = location


  function afterPageLoad(){
    console.log("New page load")
    // if ezoic
    if( process.env.EZOIC_ACCOUNT_ID ){
      var ezstandalone = ezstandalone || {};
      ezstandalone.cmd = ezstandalone.cmd || [];
      ezstandalone.cmd.push(function() {
        // If the placeholder ID’s change make sure to redefine, if not just call refresh
        console.log("Ezoic ... define")
        ezstandalone.define(100,102,101,104);
        if (ezstandalone.enabled) {
          ezstandalone.refresh();
          console.log("Ezoic .. refresh")
        } else {
          console.log("Ezoic .. display")
          ezstandalone.enable();
          ezstandalone.display();
        }
      });
    }

    // if adsense
    if ( process.env.ADSENSE_CLIENT_ID ){
        var window = window || {};
        window.adsbygoogle = window.adsbygoogle || [];
        const nGoogleAds = document.getElementsByClassName("adsbygoogle").length;
        // load ad for each
        console.log("checking for google ads...")
        for (let i = 0; i < nGoogleAds; i++) {
          console.log("ad?")
          if( document.getElementsByClassName("adsbygoogle")[i].children.length == 0 ){
            (adsbygoogle = window.adsbygoogle || []).push({});
            console.log("add ad")
          }
        }
    }
  };

  typeof window !== `undefined` && window.addEventListener('popstate', afterPageLoad());


  return (
    <Layout>
      <MetaSchemaSEO
        title = {article.title}
        article = {article}
      />
      <BlogPost article={article} search_index={process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME}/>
      
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy/>
          <style jsx>{`
            .infinite-scroll-component {
              overflow: unset !important;
            }
            .adsbygoogle {
              background: #80808014;
            }
          `}</style>
        </React.Suspense>
      )}
    </Layout>
  );

};

export default Page;
