import React from 'react'

import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {format} from 'date-fns';

import { getEntityUrl } from '../lib/helpers'

import Breadcrumb from '../components/breadcrumb'
import Container from '../components/container'

import * as styles from './article-header.module.css'



export default function ArticleHeader(props) {
    const article = props.article
    const image = getImage(article?.main_image?.imageFile)
    
    // author spacing
    var author_spacer = ""
    var author_spacer_last = ""
    if(article.authors.length === 2){
        author_spacer_last = " and "
    } else if (article.authors.length > 2) {
        author_spacer = ", "
        author_spacer_last = ", and "
    }

    // decide if you show the image or not
    var has_image = article?.main_image?1:0
    var has_override = article.page_type?.show_hero_image===null?0:1
    var force_show = has_override & (article.page_type?.show_hero_image?1:0)
    var force_hide = has_override & (article.page_type?.show_hero_image?0:1)
    var has_children = (article.children && article.children.length === 0)?0:1
    var show_main_image = has_image && ((has_children&&has_override)?force_show:1) && !force_hide && (has_override?force_show:1)

    return (
        <>
            <div className={styles.pageHeader}>
                <Container>
                    <Breadcrumb thisPage={article}></Breadcrumb>
                    <h1 className={styles.title}>{article.title}</h1>
                </Container>
            </div>
            
            <div className={styles.pageSubHeader}>
                {article.authors && article.authors.length>0 &&
                    
                    <div className={styles.authors}>
                        <div className={styles.avatars}>
                        {article.authors.map(entity => (
                            <>
                                {entity.main_image &&
                                    <span key={entity._id} className={styles.avatar}>
                                        <GatsbyImage 
                                            className={styles.avatarImg} 
                                            image={getImage(entity?.main_image?.imageFile)} 
                                            alt={entity.main_image.alternativeText ? entity.main_image.alternativeText : entity.name } />
                                    </span>
                                }
                            </>
                        ))}
                        </div>
                        <div className={styles.mediaBody}>
                            By {article.authors.map((entity, index) => (
                                <>
                                    <Link key={entity._id} to={getEntityUrl(entity)}>{entity.name}</Link>
                                    { index<(article.authors.length-2) ? author_spacer:""}
                                    { index===(article.authors.length-2) ? author_spacer_last:""}
                                </>
                            ))}
                            <small className={styles.dateBlock}>
                                { article.created_at && !article.updated_at &&
                                    <>{format( Date.parse(article.created_at), "MMMM do, yyyy")}</>
                                }
                                { article.updated_at && 
                                    <>Updated {format( Date.parse(article.updated_at), "MMMM do, yyyy")}</>
                                }
                            </small>
                        </div>
                    </div>
                }
                { (!article.authors || (article.authors && article.authors.length===0)) &&
                    <small className={styles.dateBlock}>
                        { article.created_at && !article.updated_at && 
                            <>{format( Date.parse(article.created_at), "MMMM do, yyyy")}</>
                        }
                        { article.updated_at && 
                            <>Updated {format( Date.parse(article.updated_at), "MMMM do, yyyy")}</>
                        }
                    </small>
                }
                {article.authorities && article.authorities>0 && 
                    <ul>
                        {article.authorities.map(entity => (
                            <li key={entity._id}>
                                <Link to={getEntityUrl(entity)}>{entity.name}</Link>
                            </li>
                        ))}
                    </ul>
                }
                { article.summary && 
                <Container>
                        <blockquote><i>{article.summary}</i></blockquote>
                </Container>
                }
            </div>
            {
                show_main_image?(
                <div className={styles.hero}>
                    <GatsbyImage image={image} alt={article.main_image.alternativeText ? article.main_image.alternativeText : article.title } />
                    <div className={styles.heroImageCopyright}>{article.main_image.caption}</div>
                </div>):null
            }
        </>
    )
  }