import React from 'react'
import Markdown from "react-markdown"

import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import * as styles from './about-sidecallout.module.css'


export const query = graphql`
  query {
    about: strapi{ aboutSetting {
        sidecallout_text
        sidecallout_image{
            url
            caption
            alternativeText
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
      }
      siteSetting {
        color_accent_very_light
      }
    }
  }
`

const AboutSideCallout = () => {
    const data = useStaticQuery(query);
    const about = data.about.aboutSetting;
    const image = getImage(about?.sidecallout_image?.imageFile)
    var siteSettings_bgcolor = "ffffff"
    if(data.about.siteSetting.color_accent_very_light){
      siteSettings_bgcolor = data.about.siteSetting.color_accent_very_light
    }

  return (
    <div className={styles.root}> 
    { about && 
      <>
        <div className={styles.callout} id="calloutdiv">
            { about && about.sidecallout_image &&
            <span className={styles.hero}>
                <GatsbyImage image={image} alt={about.sidecallout_image.alternativeText ? about.sidecallout_image.alternativeText : "" } />
            </span>
            }
            <div className={styles.callout_text}>
                <Markdown children={about.sidecallout_text} skipHtml={true} />
            </div>
        </div>
        <style jsx>{`
          #calloutdiv {
            background: #`+siteSettings_bgcolor+`;
          }
        `}</style>
      </>
    }
    </div>
  )
}

export default AboutSideCallout
